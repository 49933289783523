<template>
  <div id="game-1">
    <header>
      <!-- <button @click="isGameStart = false" v-if="isGameStart">MENU</button> -->
      <div class="back-btn">
        <router-link to="/games">
          <i class="fas fa-chevron-left white-text"></i>
          <label>WYJDŹ</label>
        </router-link>

        <!-- Witaj {{ user.displayName }} -->
      </div>
      <div>
        <i style="display:none;" @click="showHelpScreen = !showHelpScreen" class="fas fa-question-circle"></i>
      </div>
    </header>

    <div id="intro-screen" class="content" v-if="!isGameStart">
      <audio
        id="intro-audio"
        src="https://firebasestorage.googleapis.com/v0/b/miasto-to-gra.appspot.com/o/game1%2Flas.mp3?alt=media&token=2aedc4cd-62b1-48f5-ad7e-ad43eb026f7e"
        autoplay
        loop
      ></audio>

      <div class="box">
        <p>
          Witaj! Jestem Feliks. Jestem strażnikiem tych oto pięknych gór. Zapraszam Cię od gry, podczas której poznasz zwierzęta na co dzień żyjące w karkonoskim lesie. Dzięki tej niezwykłej przygodzie dowiesz się, co jedzą, w jaki sposób opiekują się młodymi, gdzie szukają schronienia i jak polują. Podczas zabawy wykonuj zadania i zdobywaj punkty. Rozszyfruj hasło, które ukryłem przed innymi.
        </p>
        <div class="triangle"></div>
      </div>

      <button v-if="selectedMission == -1" @click="startGame()">
        Rozpocznij przygodę
      </button>
      <button v-else @click="returnGame()">Wznów przygodę</button>
    </div>

    <div
      id="menu-screen"
      :class="{ correct: isCorrectPlace, wrong: isCorrectPlace == false }"
      v-if="isGameStart && !isOnPlace"
    >
      <div class="topbar">
        <div class="timer">
          <i class="fas fa-clock"></i>
          {{ userTimer }}
        </div>

        <div class="progressbar">
          <div
            class="progress"
            :style="{ width: (progress / missions.length) * 100 + '%' }"
          ></div>
          <label>{{ progress }} / {{ missions.length }}</label>
        </div>

        <div class="points">
          <i class="fas fa-star"></i>
          {{ userPoints }}
        </div>
      </div>

      <div>
        <h1 class="white-text">Znajdź to miejsce</h1>
        <p class="white-text">Rozejrzyj się i wybierz prawidłowo</p>
        <br />

        <div class="place-selector">
          <div
            v-for="(image, index) in missions[selectedMission].placeImages"
            :key="index"
            @click="selectPlace(image, index)"
            :class="{ bigger: index == selectedPlaceIndex }"
          >
            <div :style="{ backgroundImage: 'url(' + image + ')' }"></div>
          </div>
        </div>
      </div>

      <div></div>
    </div>

    <div id="game-screen" v-if="isGameStart && isOnPlace">
      <div id="background-wrap">
        <div class="x1">
          <div class="cloud"></div>
        </div>

        <div class="x2">
          <div class="cloud"></div>
        </div>

        <div class="x3">
          <div class="cloud"></div>
        </div>

        <div class="x4">
          <div class="cloud"></div>
        </div>

        <div class="x5">
          <div class="cloud"></div>
        </div>
      </div>

      <div class="scene">
        <div class="topbar">
          <div class="timer">
            <i class="fas fa-clock"></i>
            {{ userTimer }}
          </div>

          <div class="progressbar">
            <div
              class="progress"
              :style="{ width: (progress / missions.length) * 100 + '%' }"
            ></div>
            <label>{{ progress }} / {{ missions.length }}</label>
          </div>

          <div class="points">
            <i class="fas fa-star"></i>
            {{ userPoints }}
          </div>
        </div>

        <div class="content">
          <div class="question-box">
            <button
              v-if="selectedBonus != null"
              class="bonus"
              @click="bonusQuestionActive = true"
            >
              Pytanie bonusowe:
              <i class="fas fa-star" style="font-size: 1.2rem"></i>+10
            </button>

            <div class="box question">
              <video v-if="missions[selectedMission].video" controls>
                <source
                  :src="missions[selectedMission].video"
                  type="video/mp4"
                />
              </video>

              <img
                v-if="missions[selectedMission].image"
                :src="missions[selectedMission].image"
              />

              <p>{{ missions[selectedMission].question }}</p>
              <div class="triangle"></div>
            </div>

            <!-- <div class="character"></div> -->
          </div>

          <div class="answer-buttons">
            <div
              v-for="(answer, index) in missions[selectedMission].answers"
              :key="index"
              @click="selectAnswer(answer, index)"
              :class="{
                answerButton: true,
                'correct-text':
                  correct && answer === missions[selectedMission].correctAnswer,
                'wrong-text': index == wrongIndex,
                bigger: index == selectedAnswerIndex,
              }"
              :disabled="questionIsDone"
            >
              {{ answer }}
            </div>
          </div>
        </div>
      </div>

      <div class="password-box">
        <div>
          <div v-for="(slot, index) in passwordSlots" :key="index">
            <div class="empty-slot" v-if="pass[index] == ' '"></div>
            <div
              :class="{
                slot: true,
                correctAnswer: correct && randomLetter === slot,
              }"
              v-else
            >
              {{ slot }}
            </div>
          </div>
        </div>
      </div>

      <div class="win-screen" v-if="questionIsDone">
        <div>
          <h1>{{ missions[selectedMission].correctAnswer }}</h1>
        </div>

        <div class="win-screen-info">
          <p>{{ missions[selectedMission].slideInfo }}</p>
          <video controls autoplay loop preload="none">
            <source :src="currentSlideVideo" type="video/mp4" />
          </video>
          <div class="features">
            <div
              v-for="(feature, index) in missions[selectedMission].slideParams"
              :key="index"
            >
              {{ feature }}
              <br />
              <label>{{ slideParamsName[index] }}</label>
            </div>
          </div>
        </div>

        <div>
          <button
            @click="nextMission()"
            v-if="selectedMission != missions.length - 1"
          >
            Kontynuuj przygodę
          </button>
          <div v-else>
            <p>
              To już koniec tej przygody.<br />Zapisz hasło, które udało Ci się
              odkryć!
            </p>
            <br />
            <p>
              <strong>{{ pass }}</strong>
            </p>
            <br />
            <button @click="exitGame()">Wyjdź z gry</button>
          </div>
        </div>
      </div>

      <div class="bonus-screen" v-if="bonusQuestionActive">
        <div>
          <h1>Pytanie bonusowe</h1>
          <h2>
            Zdobądź <i class="fas fa-star" style="font-size: 1.2rem"></i>+10
          </h2>
          <div class="q-box">
            <p>
              {{ selectedBonus.question }}
            </p>
            <div class="triangle"></div>
          </div>
        </div>

        <div class="answer-buttons">
          <div
            v-for="(answer, index) in selectedBonus.answers"
            :key="index"
            @click="selectBonusAnswer(answer, index)"
            :class="{
              'answerButton': true,
              'correct-text': correct && answer === selectedBonus.correctAnswer,
              'wrong-text': index == wrongIndex,
              'bigger': index == selectedAnswerIndex,
            }"
            :disabled="questionIsDone"
          >
            {{ answer }}
          </div>
        </div>
      </div>
    </div>

    <help-screen v-if="showHelpScreen" :pages="helpPages"></help-screen>
  </div>
</template>

<script>
import HelpScreen from "@/components/HelpScreen";
import firebase from "@/firebase";

export default {
  components: {
    HelpScreen,
  },
  data() {
    return {
      showHelpScreen: false,
      helpPages: [
        {
          title: "Jak grać?",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, totam sit commodi ducimus, temporibus enim a blanditiis aliquid porro quae quidem non incidunt quam provident distinctio impedit quas. Assumenda, voluptate.",
        },
        {
          title: "Jak grać?",
          content:
            "Ipsum dolor sit amet consectetur adipisicing elit. Expedita, totam sit commodi ducimus, temporibus enim a blanditiis aliquid porro quae quidem non incidunt quam provident distinctio impedit quas. Assumenda, voluptate.",
        },
      ],
      pass: "Dbając o przyrodę dbamy o zwierząt swobodę",
      password: [],
      passwordSlots: [],
      gameComplete: false,
      isGameStart: false,
      isOnPlace: false,
      isCorrectPlace: null,
      selectedPlaceIndex: null,
      selectedAnswerIndex: null,
      selectedMission: -1,
      progress: null,
      questionIsDone: false,
      correct: false,
      wrongIndex: -1,
      randomLetter: "",
      currentSlideVideo: "",
      userPoints: 0,
      userTimer: "00:00:00",
      startDateTime: null,
      bonusQuestionActive: false,
      bonusQuestions: [
        {
          id: 2,
          question: "Czy wiesz, że w tym miejscu do roku 2017 stała skocznia narciarska Karpatka? Ile wynosił punkt konstrukcyjny tego obiektu? Szukaj na kamieniach wzdłuż drogi.",
          answers: ["K35", "K70", "K120", "K55"],
        },
        {
          id: 5,
          question: "Zbudowana w latach 1910-1915 półkolista zapora wodna na rzece Łomnica ma chronić miasto przed zagrożeniem powodziowym. Iloma przelewami przedostaje się przez nią woda?",
          answers: ["5", "7", "3", "9"],
        },
        {
          id: 9,
          question: "W Karkonoszach spotkać można skały różnego pochodzenia: metamorficzne, osadowe i  magmowe. Z tych ostatnich zbudowany jest szczyt na którym stoisz. Jaka to skała?",
          answers: ["Granit", "Wapień", "Piaskowiec", "Marmur"],
        },
        {
          id: 13,
          question: "Działanie 2+3x2 podziel na ilość samotnych świerków na początku tej drogi",
          answers: ["4", "5", "2", "10"],
        },
        {
          id: 18,
          question: "Które ze zwierząt nie zostało zaproszone na ucztę w Parku Liczyrzepy?",
          answers: ["Ryś", "Wilk", "Niedźwiedź", "Dzik"],
        },
      ],
      selectedBonus: null,
      slideParamsName: ["długość", "waga", "wiek"],
      missions: [
        {
          placeImages: [
            "/game1/imgs/1a.jpg",
            "/game1/imgs/1b.jpg",
          ],
          question:
            "Potrafi naśladować głosy innych ptaków, a nawet…  miauczenie kota! Jest ciekawska i hałaśliwa, wystrojona w kolorowe piórka. Wbrew temu co sugeruje w swoim wierszu Jan Brzechwa, ona wcale nie wybiera się za morze.",
          answers: ["Sójka zwyczajna", "Pustułka", "Czeczotka", "Kopciuszek"],
          video: "",
          image: "",
          slideInfo:
            "Sójka jest leśnym strażnikiem. Jej sygnały alarmowe ostrzegają inne gatunki o obecności drapieżników. Jesienna aktywność sójki, która  nie prowadzi  jednak do odlotu, stała się źródłem powiedzenia: „wybierać się jak sójka za morze”. W tym czasie ptak ten gromadzi pożywienie na zimę.",
          slideImage: "",
          slideVideo: "game1/1Sojkazwyczajna.mp4",
          slideParams: ["35 cm", "180 g", "75 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/2a.jpg",
            "/game1/imgs/2b.jpg",
          ],
          question:
            "Jest największym w Polsce płazem ogoniastym, kontrastowo ubarwiona, widoczna jest nawet w nocy. Specjalne kolory ostrzegawcze na jej skórze, informują napastników o silnych toksynach zawartych w jej gruczołach skórnych. Nie cierpi upałów. Po ulewnym deszczu pokonuje duże odległości na lądzie, w poszukiwaniu swojej przysłowiowej drugiej połówki.",
          answers: [
            "Salamandra plamista",
            "Legwan",
            "Kameleon",
            "Traszka górska",
          ],
          video: "",
          image: "",
          slideInfo:
            "Salamandry są w stanie zregenerować utracone kończyny w ciągu kilku tygodni, w tym ogony i palce u nóg, co pozwala im przetrwać. Nie spotyka się też dwóch osobników o takim samym układzie i kształcie plam.",
          slideImage: "",
          slideVideo: "game1/2Salamandraplamista.mp4",
          slideParams: ["30 cm", "19 g", "10 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/3a.jpg",
            "/game1/imgs/3b.jpg",
          ],
          question:
            "Potrafi szybko i zwinne wspinać się po pniach drzew i skakać po gałęziach dzięki silnym tylnym nogom. Długa puszysta kita pomaga jej zachować równowagę. Co roku przed zimą zbiera zapasy grzybów, orzechów i nasion.",
          answers: [
            "Wiewiórka pospolita",
            "Kuna leśna",
            "Gronostaj",
            "Zając szarak",
          ],
          video: "",
          image: "",
          slideInfo:
            "Wiewiórka uciekając przed zagrożeniem, zawsze biegnie zygzakiem. Przednie zęby wiewiórek nigdy nie przestają rosnąć.",
          slideImage: "",
          slideVideo: "game1/3Wiewiorkapospolita.mp4",
          slideParams: ["24 cm", "330 g", "5 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/4a.jpg",
            "/game1/imgs/4b.jpg",
          ],
          question:
            "Samiec posiada duże szczęki przypominające rogi. Nacina nimi kory drzew, z których wypływają soki, będące jego pożywieniem. Toczy z innymi samcami pojedynki o względy pięknych pań.",
          answers: [
            "Jelonek Rogacz",
            "Pasikonik",
            "Mącznik Młynarek",
            "Mrówka",
          ],
          video: "",
          image: "",
          slideInfo:
            "Jelonek rogacz jest jednym z największych chrząszczy żyjących w Polsce. Rogi jelonka mogą stanowić aż połowę długości jego ciała.",
          slideImage: "",
          slideVideo: "game1/13Jelonekrogacz.mp4",
          slideParams: ["8 cm", "50 g", "1 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/5a.jpg",
            "/game1/imgs/5b.jpg",
          ],
          question:
            "Jest jedynym ptakiem śpiewającym, który potrafi dobrze nurkować i pływać. Słynie ze spacerów po dnie górskich potoków w poszukiwaniu pokarmu.",
          answers: ["Pluszcz", "Pliszka górska", "Muchówka mała", "Mornel"],
          video: "",
          image: "",
          slideInfo:
            "Pluszcz posiada silne nogi zaopatrzone w mocne pazury.  Ciężkie i częściowo wypełnione kości są jak obciążniki w trakcie nurkowania. Potrafi pozostawać pod powierzchnią wody do 30 sekund.",
          slideImage: "",
          slideVideo: "game1/10Pluszcz.mp4",
          slideParams: ["18 cm", "62 g", "10 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/6a.jpg",
            "/game1/imgs/6b.jpg",
          ],
          question:
            "Jest leśnym doktorem. Swoim mocnym dziobem drąży korę drzew i wyjada żyjące w niej owady, dzięki czumu chroni drzewa przed niebezpiecznymi dla nich szkodnikami. Samiec zawsze na głowie ma czerwoną czapkę a samica czerwony pasek na potylicy. Jego czaszka wyposażona jest w dodatkowe mięśnie, które działają jak ochronny kask dla mózgu.",
          answers: ["Dzięcioł czarny", "Kukułka", "Sowa Włochatka", "Zięba"],
          video: "",
          image: "",
          slideInfo:
            "Dzięcioł czarny jest największym występującym w Polsce dzięciołem. Bębni w drzewo z prędkością 15-20 uderzeń na minutę. Długość języka jest często równa długości całego ciała dzięcioła. Nie jest on przymocowany do głowy zwierzęcia, jak u większości ptaków, ale owija się wokół czaszki.",
          slideImage: "",
          slideVideo: "game1/4Dzięciołczarny.mp4",
          slideParams: ["50 cm", "320 g", "14 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/7a.jpg",
            "/game1/imgs/7b.jpg",
          ],
          question:
            "W Karkonosze został sprowadzony około 100 lat temu. Ma grube ślimakowato zakręcone rogi. Samce rzadko wydają głos, najczęściej usłyszeć można samice.",
          answers: ["Muflon", "Kozica północna", "Daniel", "Żbik"],
          video: "",
          image: "",
          slideInfo:
            "Muflon został sprowadzony z Sardynii i Kostaryki. Jest przodkiem owcy domowej a zarazem najmniejszym przedstawicielem dzikich owiec. Muflony są wyjątkowa płochliwymi zwierzętami. Bardzo dobrze słyszą i widzą. Rogi Muflona ważą nawet 3 kg i mogą mieć długość do 80 cm.",
          slideImage: "",
          slideVideo: "game1/7Muflon.mp4",
          slideParams: ["130 cm", "50 kg", "20 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/8a.jpg",
            "/game1/imgs/8b.jpg",
          ],
          question: "Kto pozostawił takie tropy?",
          answers: ["Wilk szary", "Niedźwiedź", "Pies", "Dzik europejski"],
          video: "",
          image:
            "https://firebasestorage.googleapis.com/v0/b/miasto-to-gra.appspot.com/o/game1%2Fwilktropy2.jpg?alt=media&token=d5e483c9-1559-4e43-877d-0f2656424ba6",
          slideInfo:
            "Wilcze ślady układają się wzdłuż linii prostej. Wataha wilków porusza się „łapa w łapę” jednym sznurem. Psy nigdy tak nie robią – gdy idą razem, nie zwracają uwagi na to, by stawiać łapy w tym samym miejscu. Ślad łapy wilka jest duży. Między odbiciem poduszek można wyraźnie zauważyć literę X.",
          slideImage: "",
          slideVideo: "game1/6Wilk.mp4",
          slideParams: ["200 cm", "80 kg", "14 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/9a.jpg",
            "/game1/imgs/9b.jpg",
          ],
          question:
            "Największy ptak szponiasty Polski i całej północnej Europy. W jego sylwetce w locie uwagę zwracają przede wszystkim szerokie, deskowate skrzydła, krótki ogon oraz duży, masywny dziób.",
          answers: ["Bielik", "Kruk", "Sokół wędrowny", "Derkacz"],
          video: "",
          image: "",
          slideInfo:
            "W Karkonoszach znajduje się jedno z najwyżej położonych stanowisk lęgowych Bielika. Gniazda o masie 1 tony, 2,5 metra średnicy i do 4 metrów wysokości budują najczęściej na 100 letnich i starszych sosnach.",
          slideImage: "",
          slideVideo: "game1/17Bielik.mp4",
          slideParams: ["92 cm", "6,7 kg", "20 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/10a.jpg",
            "/game1/imgs/10b.jpg",
          ],
          question:
            "Kurak mieszkający w Karkonoszach w pobliżu górnej granicy lasu. W przeciwieństwie do swoich kuzynów Głuszców czy Jarząbków, lubi tereny leśne oraz w pełni otwarte, najlepiej podmokłe. Jest ostrożny, unika ludzi i potrzebuje przestrzeni.",
          answers: ["Cietrzew", "Sóweczka", "Podróżniczek", "Świstak"],
          video: "",
          image: "",
          slideInfo:
            "Cietrzew jest gatunkiem chronionym, silnie zagrożonym wyginięciem. Potrafi latać na dalekie dystanse. W trakcie toków może dochodzić do walk kogutów, które stają się zacietrzewione i w trakcie potyczki mogą wyrywać sobie pióra.",
          slideImage: "",
          slideVideo: "game1/16Cietrzew.mp4",
          slideParams: ["68 cm", "1,1 kg", "6 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/11a.jpg",
            "/game1/imgs/11b.jpg",
          ],
          question:
            "Prowadzi wyjątkowo dyskretne życie. Bez trudu wspina się po drzewach i pływa w wartkich rzekach. Nie przeszkadza mu zimno. Można spotkać go nawet w tundrze na obszarze podbiegunowym. Dzięki cętkom na futrze z łatwością wtapia się w otoczenie.",
          answers: ["Ryś", "Rosomak", "Sarna", "Borsuk"],
          video: "",
          image: "",
          slideInfo:
            "Ryś podobnie jak kot domowy, wiele czasu spędza na spaniu. Jego łapy pełnią funkcję naturalnych rakiet śnieżnych.  Uszy rysia są duże, ruchliwe i wyposażone w kępki włosów na czubkach, co umożliwia szybką lokalizację dźwięku i pozwala wyłapywać wysokie częstotliwości 60-70 kHz.",
          slideImage: "",
          slideVideo: "game1/5Rys.mp4",
          slideParams: ["69 cm", "8 kg", "7 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/12a.jpg",
            "/game1/imgs/12b.jpg",
          ],
          question:
            "W ciągu dnia odpoczywa w barłogu wyłożonym suchą trawą i liśćmi. Latem chłodzi się kąpielami błotnymi, które chronią go też przed ukąszeniami owadów. Gdy czuje się zagrożony, broni się ostrymi kłami.",
          answers: ["Dzik", "Jeż", "Gronostaj", "Bóbr"],
          video: "",
          image: "",
          slideInfo:
            "Dziki nie zapadają w sen zimowy. Mimo swojego wyglądu potrafią szybko biegać. Odgrywają ważną rolę w ekosystemie poprzez spulchnianie gleby oraz zjadanie szkodników  i chorych ssaków. Ulubioną zabawą dzika jest tarzanie się w błocie. Należy do zwierząt towarzyskich. Żyje w stadzie, które jest jego rodziną.",
          slideImage: "",
          slideVideo: "game1/14Dzik.mp4",
          slideParams: ["2 m", "300 kg", "15 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/13a.jpg",
            "/game1/imgs/13b.jpg",
          ],
          question:
            "Spotkać ją można na obrzeżach lasów, polanach i nasłonecznionych stokach. Najczęściej ucieka lub chowa się przed człowiekiem. Atakuje w ostateczności, gdy jest osaczona, atakowana lub przypadkowo nadepnięta. Jest jedynym jadowitym wężem żyjącym w Polsce.",
          answers: [
            "Żmija zygzakowata",
            "Zaskroniec zwyczajny",
            "Padalec zwyczajny",
            "Jaszczurka żyworodna",
          ],
          video: "",
          image: "",
          slideInfo:
            "Żmija zygzakowata ma charakterystyczny znak X, Y lub V na głowie i zygzak na grzbiecie. Podlega ochronie gatunkowej. Żmija tak jak i inne gady dzięki elastycznemu połączeniu górnej szczęki i żuchwy jest w stanie połknąć stworzenie przekraczające rozmiary swojej głowy.",
          slideImage: "",
          slideVideo: "game1/9Zmijazygzakowata.mp4",
          slideParams: ["80 cm", "200 g", "16 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/14a.jpg",
            "/game1/imgs/14b.jpg",
          ],
          question:
            "Jest bardzo podstępny, ciekawski, inteligentny i zazwyczaj nie śpi w nocy. Żyje i poluje samotnie. Ma mały brzuszek, więc codziennie je wiele małych posiłków. Ma znakomity słuch i wzrok. Zimą rzadko kryje się w norach, najczęściej zwija się w kłębek i pozwala przykryć się przez śnieg.",
          answers: ["Lis rudy", "Wiewiórka", "Królik", "Zając szary"],
          video: "",
          image: "",
          slideInfo:
            "Lis rudy potrafi skakać na wysokość ponad 2 metrów. Lisy po urodzeniu nie chodzą i nie otwierają oczu. Pozostają  pod całkowitą opieką swojej mamy.",
          slideImage: "",
          slideVideo: "game1/15Lisrudy.mp4",
          slideParams: ["90 cm", "14 kg", "5 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/15a.jpg",
            "/game1/imgs/15b.jpg",
          ],
          question:
            "Bywa bardzo towarzyska i hałaśliwa. Lubi łatwy pokarm, więc często jada dosłownie z ręki. Lata nisko i na długie dystanse. Jest najliczniejszym przedstawicielem swojej rodziny. Można ją spotkać praktycznie na całym świecie-głównie nad rzeczką opodal krzaczka.",
          answers: [
            "Kaczka krzyżówka",
            "Zimorodek",
            "Cietrzew",
            "Drozd obrożny",
          ],
          video: "",
          image: "",
          slideInfo:
            "Kaczki nie przymarzają do lodu i myślą tylko połową mózgu. Wypracowały sobie taki system, że jednocześnie mogą odpoczywać i czuwać nad swoim i stada bezpieczeństwem.",
          slideImage: "",
          slideVideo: "game1/12Kaczkakrzyzowka.mp4",
          slideParams: ["65 cm", "1,6 kg", "9 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/16a.jpg",
            "/game1/imgs/16b.jpg",
          ],
          question:
            "Prowadzi samotniczy tryb życia. Rośnie bardzo powoli. Na ciele widoczne ma czarne i czerwone kropki. Posiada zęby na obu szczękach. Poluje na różne organizmy wodne, a także latające owady. Zamieszkuje najczęściej odcinki strumieni i rzek o dobrze natlenowanej wodzie z szybkim nurtem, z  kamienistym dnem.",
          answers: ["Pstrąg potokowy", "Płoć", "Karp", "Karaś"],
          video: "",
          image: "",
          slideInfo:
            "Pstrąg potokowy składa ikrę,  wędrując w górę rzek, płynąc pod prąd, podobnie jak czynią to łososie.  Oddycha tlenem rozpuszczonym w wodzie za pomocą skrzeli. Jak każda ryba nie posiada ucha zewnętrznego. Ma natomiast ucho wewnętrzne umiejscowione w głowie, które pełni również rolę narządu równowagi.",
          slideImage: "",
          slideVideo: "game1/11Pstragpotokowy.mp4",
          slideParams: ["80 cm", "3 kg", "7 lat"],
        },
        {
          placeImages: [
            "/game1/imgs/17a.jpg",
            "/game1/imgs/17b.jpg",
          ],
          question:
            "Motyl ten wyginął definitywnie w Sudetach już w XIX wieku. Jest skrajnie zagrożonym gatunkiem. Pracownicy Karkonoskiego Parku Narodowego w 2007 roku przystąpili do odbudowania jego populacji. Jest jednym z największych w Polsce motyli dziennych.",
          answers: [
            "Niepylak Apollo",
            "Rusałka Pawik",
            "Miedziopierś górska",
            "Cytrynek",
          ],
          video: "",
          image: "",
          slideInfo:
            "Białe ubarwienie skrzydeł z czarnymi i czerwonymi plamkami ma cel ochronny. Dzięki niemu motyl zlewa się z barwą skał wapiennych i białych kwiatów. Nazwę Niepylak  zawdzięcza temu, że nie ma łusek, dlatego nie zostawia na palcach pyłku.",
          slideImage: "",
          slideVideo: "game1/18Niepylakapollo.mp4",
          slideParams: ["8 cm", "2 g", "40 dni"],
        },
        {
          placeImages: [
            "/game1/imgs/18a.jpg",
            "/game1/imgs/18b.jpg",
          ],
          question:
            "Jest największym ssakiem żyjącym w Karkonoszach. Jego głowę zdobi poroże, które staje się bronią podczas pojedynków. Samiec traci je wiosną, ale szybko wyrasta mu nowe, z roku na rok coraz większe, dzięki czemu łatwo określić wiek danego osobnika.",
          answers: ["Jeleń Szlachetny", "Łania", "Niedźwiedź", "Żubr"],
          video: "",
          image: "",
          slideInfo:
            "Jeleń szlachetny to trzeci co do wielkości po żubrze i łosiu ssak parzystokopytny w Polsce. Dorosłe samice jeleni nazywane są łaniami, natomiast dorosłe samce – bykami. Jelenie mają duże i rozłożyste poroże, co powoduje, że unikają one terenów o dużym stopniu zakrzewienia.",
          slideImage: "",
          slideVideo: "game1/8Jelen.mp4",
          slideParams: ["140 cm", "230 kg", "15 lat"],
        },
      ],
    };
  },
  mounted() {
    // Autogenerate password objects
    for (let i = 0; i < this.pass.length; i++) {
      this.password.push(this.pass[i].toUpperCase());
      this.passwordSlots.push("");
    }

    this.shuffleAnswers(); // Wymieszaj odpowiedzi

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.commit("SET_USER", user);
        this.getUserData(); // Odkomentuj aby włączyć zapis w chmurze
        this.progress = this.selectedMission + 1;
      } else {
        this.$router.push("/");
      }
    });
  },
  methods: {
    async getUserData() {
      const db = firebase.firestore();
      const ref = db.collection("users").doc(this.$store.state.currentUser.uid);
      const doc = await ref.get();
      if (!doc.exists) {
        console.log("No such document!");
        this.$router.push("/games");
      } else {
        if (doc.data().games.game1a.active == false || doc.data().games.game1a.gameComplete == true) {
          this.$router.push("/games");
        }
        if (doc.data().games.game1a.missions) {
          console.log("Restore from save");
          this.missions = doc.data().games.game1a.missions;
          this.selectedMission = doc.data().games.game1a.selectedMission;
          this.passwordSlots = doc.data().games.game1a.passwordSlots;
          this.password = doc.data().games.game1a.password;
          this.gameComplete = doc.data().games.game1a.gameComplete;
          this.userPoints = doc.data().games.game1a.userPoints;
          if (doc.data().games.game1a.startDateTime) {
            this.startDateTime = doc.data().games.game1a.startDateTime;
          }
        } else {
          console.log("Save not exist!");
        }
      }
    },
    async updateUserData() {
      const gameData = {
        name: "Tajemnice Karkonoskiego Lasu - zwierzęta (trasa łatwa)",
        url: "/game-1a",
        active: true,
        pass: "Dbając o przyrodę dbamy o zwierząt swobodę",
        missions: this.missions,
        selectedMission: this.selectedMission,
        passwordSlots: this.passwordSlots,
        password: this.password,
        gameComplete: this.gameComplete,
        userPoints: this.userPoints,
        startDateTime: this.startDateTime,
      };

      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(this.$store.state.currentUser.uid)
        .update({
          "games.game1a": gameData,
        });
    },
    shuffleAnswers() {
      this.missions.forEach((mission) => {
        mission.correctAnswer = mission.answers[0]; // Wybranie prawidłowej odpowiedzi
        mission.answers.sort(() => Math.random() - 0.5); // Wymieszanie odpowiedzi

        mission.correctPlace = mission.placeImages[0]; // Wybranie prawidłowej odpowiedzi
        mission.placeImages.sort(() => Math.random() - 0.5); // Wymieszanie odpowiedzi
      });

      this.bonusQuestions.forEach((bonus) => {
        bonus.correctAnswer = bonus.answers[0]; // Wybranie prawidłowej odpowiedzi pytania bonusowego
        bonus.answers.sort(() => Math.random() - 0.5); // Wymieszanie odpowiedzi
      });
    },
    async startGame() {
      this.isGameStart = true;
      this.selectedMission = 0;
      this.getVideo(this.missions[this.selectedMission].slideVideo);

      this.startDateTime = new Date();

      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(this.$store.state.currentUser.uid)
        .update({
          "games.game1a.startDateTime": this.startDateTime,
        });

      setInterval(() => {
        this.setTimer(this.startDateTime);
      }, 1000);
    },
    returnGame() {
      this.isGameStart = true;

      this.setTimer(this.startDateTime.toDate());
      setInterval(() => {
        this.setTimer(this.startDateTime.toDate());
      }, 1000);
    },
    async exitGame() {
      this.gameComplete = true;

      let endDateTime = new Date();

      const gameData = {
        name: "Tajemnice Karkonoskiego Lasu - zwierzęta (trasa łatwa)",
        url: "/game-1a",
        active: true,
        pass: "Dbając o przyrodę dbamy o zwierząt swobodę",
        missions: this.missions,
        selectedMission: this.selectedMission,
        passwordSlots: this.passwordSlots,
        password: this.password,
        gameComplete: this.gameComplete,
        userPoints: this.userPoints,
        startDateTime: this.startDateTime,
        endDateTime: endDateTime
      };

      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(this.$store.state.currentUser.uid)
        .update({
          "games.game1a": gameData,
        });

      this.$router.push("/games");
    },
    setTimer(startTime) {
      let now = new Date();
      let diffDate = now - startTime.getTime();

      let ms = diffDate % 1000;
      diffDate = (diffDate - ms) / 1000;
      let sec = diffDate % 60;
      diffDate = (diffDate - sec) / 60;
      let min = diffDate % 60;
      let hrs = (diffDate - min) / 60;

      if (sec < 10) sec = "0" + sec;
      if (min < 10) min = "0" + min;
      if (hrs < 10) hrs = "0" + hrs;

      this.userTimer = hrs + ":" + min + ":" + sec;
    },
    selectPlace(image, index) {
      this.isCorrectPlace = image;
      this.selectedPlaceIndex = index;

      if (image === this.missions[this.selectedMission].correctPlace) {
        setTimeout(() => {
          this.isOnPlace = true;
          this.isCorrectPlace = null;
          this.selectedPlaceIndex = null;
        }, 1000);
      } else {
        this.isCorrectPlace = false;
        this.userPoints -= 1;
        setTimeout(() => {
          this.isCorrectPlace = null;
          this.selectedPlaceIndex = null;
        }, 1000);
      }
    },
    selectAnswer(answer, index) {
      const isCorrect =
        answer === this.missions[this.selectedMission].correctAnswer
          ? true
          : false;
      this.selectedAnswerIndex = index;

      if (isCorrect) {
        let randomIndex = Math.floor(Math.random() * this.password.length);

        // Unikaj wylosowania spacji
        while (this.password[randomIndex] == " ") {
          randomIndex = Math.floor(Math.random() * this.password.length);
        }
        this.randomLetter = this.password[randomIndex];

        this.password = this.password.filter(
          (letter) => letter !== this.randomLetter
        );

        // Po każdej prawidłowej odpowiedzi powinno pokazać wszystkie randomLetter z całego hasła
        for (let i = 0; i < this.pass.length; i++) {
          if (this.pass[i].toUpperCase() == this.randomLetter.toUpperCase()) {
            this.passwordSlots[i] = this.randomLetter;
          }
        }

        this.correct = true;
        this.wrongIndex = -1;
        // this.progress++;
        this.userPoints += 3;
        setTimeout(() => {
          this.questionIsDone = true;
          this.correct = false;
          this.selectedAnswerIndex = null;
        }, 1000);
      } else {
        this.wrongIndex = index;
        this.userPoints--;
        setTimeout(() => {
          this.wrongIndex = -1;
          this.selectedAnswerIndex = null;
        }, 1000);
      }
    },
    nextMission() {
      this.selectedMission++;
      this.isOnPlace = false;
      this.questionIsDone = false;
      this.getVideo(this.missions[this.selectedMission].slideVideo);
      this.updateUserData();
    },
    bonusQuestion() {
      const question = this.bonusQuestions.find(
        (q) => q.id == this.selectedMission + 1
      );
      this.selectedBonus = question;
    },
    selectBonusAnswer(answer, index) {
      const isCorrect =
        answer === this.selectedBonus.correctAnswer ? true : false;
      this.selectedAnswerIndex = index;

      if (isCorrect) {
        this.correct = true;
        this.wrongIndex = -1;
        this.userPoints += 10;
        setTimeout(() => {
          this.correct = false;
          this.selectedAnswerIndex = null;
          this.selectedBonus = null;
          this.bonusQuestionActive = false;
        }, 1000);
      } else {
        this.wrongIndex = index;
        setTimeout(() => {
          this.wrongIndex = -1;
          this.selectedAnswerIndex = null;
          this.selectedBonus = null;
          this.bonusQuestionActive = false;
        }, 1000);
      }
    },
    getVideo(url) {
      let storage = firebase.storage();
      let videoRef = storage.ref(url);

      videoRef.getDownloadURL().then((videoUrl) => {
        this.currentSlideVideo = videoUrl;
      });
    },
  },
  computed: {
    user() {
      if (this.$store.state.currentUser) return this.$store.state.currentUser;
      else return { displayName: "" };
    },
  },
  watch: {
    selectedMission: function (newVal, oldVal) {
      console.log(oldVal);
      this.bonusQuestion(); // Sprawdzenie czy przy pytaniu występuje pytanie bonusowe
      if (this.progress < this.missions.length) this.progress = newVal + 1;
    },
  },
};
</script>

<style scoped>
button {
  all: unset;
  height: 50px;
  background-image: url(/game1/wood_btn.png);
  background-size: 100% 100%;
  font-size: 1.4rem;
  text-shadow: 0 2px 5px #000;
  color: white;
  margin: 0 auto 1rem auto;
  width: 100%;
  max-width: 300px;
  padding: 0 10px 6px 10px;
  box-sizing: border-box;
  transition: .3s all;
}

button:hover {
  animation: pushButton 0.3s linear;
}

button.bonus {
  z-index: 8;
  animation: floatingButton 1.5s linear infinite;
}

button:active {
  transform: scale(0.95);
}

#game-1 {
  /* background: #0A1032; */
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#intro-screen {
  background-image: url("/game1/bg-game1-intro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 50px);
  padding-top: 2rem;
  margin-top: 50px;
}
#intro-screen p {
  text-align: justify;
}

#menu-screen {
  min-height: calc(100vh - 50px);
  margin-top: 50px;
  /* background: linear-gradient(#17398D 50%, #1CA6ED); */
  background-image: url("/game1/bg0.jpg");
  background-size: cover;
  background-position: center top;
  transition: 0.3s background;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
}

#game-screen {
  min-height: calc(100vh - 50px);
  margin-top: 50px;
  background: linear-gradient(#17398d, #1ca6ed);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s background;
}
.scene {
  background-image: url("/game1/bg1.png");
  background-size: cover;
  background-position: center;
  height: calc(100vh - 50px - 16vh);
}
.scene .content {
  height: calc(100% - 62px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 0 !important;
}

.bonus-screen {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  padding: 1rem 1rem 2rem 1rem;
  color: #fff;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #3962cc;
  background-image: url('/game1/bg0b.jpg');
  background-size: cover;
}
.bonus-screen h1,
.bonus-screen h2 {
  color: #f0a824;
  text-shadow: 0 0 5px #000;
}
.bonus-screen .q-box {
  width: 100%;
  min-height: 50px;
  background: #fff;
  color: black;
  display: grid;
  place-items: center;
  box-shadow: 0 0 20px 0 #000;
  position: relative;
  border-radius: 2rem;
  margin-top: 2rem;
  padding: 1rem;
}

.win-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/game1/bg0.jpg");
  background-size: cover;
  background-position: center;
  z-index: 999;
  padding: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  text-shadow: 0 0 5px #000;
}
.win-screen-info {
  background-image: url("/game1/wood_sqr.png");
  background-size: 100% 100%;
  padding: 2rem;
}
.win-screen h1 {
  color: #f0a824;
  font-size: 2rem;
}
.win-screen video {
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 #000;
  width: 100% !important;
}
.win-screen .features {
  display: flex;
  justify-content: space-around;
  background: #00000096;
  border: 2px solid #f0a824;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  box-shadow: 0 0 10px 0 #000;
}
.win-screen .features label {
  font-size: 0.8rem;
  color: #f0a824;
  text-transform: uppercase;
}
.win-screen strong {
  color: #f0a824;
}

.question-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.question-box button {
  filter: hue-rotate(110deg) saturate(1.3);
}
.question-box .question {
  /* flex-basis: 100%; */
  z-index: 1;
  border-radius: 2rem;
  /* margin-bottom: 2rem; */
}
.question-box .character {
  flex-basis: 30%;
  background-image: url("/game1/player.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  z-index: 1;
}
.ground {
  height: 40px;
  width: 100%;
  background: linear-gradient(180deg, #00d000, #255a00);
  border-radius: 50%;
  position: relative;
  top: -20px;
  z-index: 0;
  box-shadow: 0 10px 10px -5px;
  z-index: 0;
}

header {
  width: 100%;
  height: 50px;
  background: #17398d;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
  z-index: 10;
}
header .back-btn a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
header label {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  margin-left: 0.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.password-box {
  width: 100%;
  height: 16vh;
  background: #17398d;
  border-top: 5px solid #f0a824;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.password-box > div {
  width: 360px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.password-box .slot {
  width: 17px;
  height: 22px;
  background: #3962cc;
  border-bottom: 2px solid #f0a824;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  /* font-weight: bold; */
}
.password-box .empty-slot {
  width: 22px;
}

.content {
  padding: 1rem;
}

.progressbar {
  width: 45%;
  height: 1.7rem;
  background: #fff;
  border-radius: 1rem;
  position: relative;
  border: 2px solid #f0a824;
  overflow: hidden;
  transition: 0.3s width;
}
.progressbar label {
  position: absolute;
  top: 0;
  left: calc(50% - 30px);
  width: 60px;
}
.progressbar .progress {
  background: #ffd380;
  width: 0%;
  height: 100%;
  border-radius: 0;
  transition: 0.3s width;
}
.topbar {
  font-family: "Oswald", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  padding: 1rem;
}
.topbar > div {
  z-index: 10;
}
.topbar .timer,
.topbar .points {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 0.3rem;
  border-radius: 1rem;
  border: 2px solid #f0a824;
  transition: 0.3s width;
}
.topbar .timer {
  min-width: 90px;
}
.topbar .points {
  min-width: 60px;
}
.topbar .timer i,
.topbar .points i {
  margin-right: 0.5rem;
  /* font-size: 1rem; */
  color: #f0a824;
}

.done-item {
  background: #5bc236;
}
.active-item {
  font-weight: bold;
  background: #17398d;
  color: white;
}
.place-selector {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}
.place-selector > div {
  width: 200px;
  height: 200px;
  background-image: url("/game1/wood_sqr.png");
  background-size: cover;
  margin: 0 auto;
  transition: 0.3s transform;
  display: grid;
  place-items: center;
}
.place-selector div > div {
  width: 160px;
  height: 160px;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 10px 0 #000;
}
.correct {
  background: #5bc236 !important;
}
.correct-text {
  color: #5bc236 !important;
}
.wrong {
  background: tomato !important;
}
.wrong-text {
  color: tomato !important;
}
.bigger {
  transform: scale(1.05);
}

#game-screen video,
#game-screen img {
  margin: 1rem auto;
  width: 80%;
}
#game-screen .answer-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  z-index: 1;
}
#game-screen .answer-buttons .answerButton {
  width: 100%;
  height: 50px;
  display: grid;
  place-items: center;
  transition: 0.3s all;
  background-image: url(/game1/wood_btn.png);
  background-size: 100% 52px;
  font-size: 1.1rem;
  letter-spacing: 1px;
  text-shadow: 0 2px 5px #000;
  padding: 0 10px 4px 10px;
  color: white;
}

/* CLOUDS BACKGROUND */

#background-wrap {
  bottom: 40%;
  left: 0;
  padding-top: 50px;
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 0;
  overflow: hidden;
}

@-webkit-keyframes animateCloud {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 100%;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 100%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 100%;
  }
}

@-webkit-keyframes animateCloudReverse {
  0% {
    margin-left: 100px;
  }
  100% {
    margin-left: -100%;
  }
}

@-moz-keyframes animateCloudReverse {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -100%;
  }
}

@keyframes animateCloudReverse {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -100%;
  }
}

.x1 {
  -webkit-animation: animateCloud 35s linear infinite;
  -moz-animation: animateCloud 35s linear infinite;
  animation: animateCloud 35s linear infinite;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x2 {
  -webkit-animation: animateCloudReverse 20s linear infinite;
  -moz-animation: animateCloudReverse 20s linear infinite;
  animation: animateCloudReverse 20s linear infinite;

  -webkit-transform: scale(0.25);
  -moz-transform: scale(0.25);
  transform: scale(0.25);
}

.x3 {
  -webkit-animation: animateCloud 30s linear infinite;
  -moz-animation: animateCloud 30s linear infinite;
  animation: animateCloud 30s linear infinite;

  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  transform: scale(0.35);
}

.x4 {
  -webkit-animation: animateCloud 20s linear infinite;
  -moz-animation: animateCloud 20s linear infinite;
  animation: animateCloud 20s linear infinite;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x5 {
  -webkit-animation: animateCloudReverse 25s linear infinite;
  -moz-animation: animateCloudReverse 25s linear infinite;
  animation: animateCloudReverse 25s linear infinite;

  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
}

.cloud {
  background: #fff;
  background: -moz-linear-gradient(top, #fff 5%, #f1f1f1 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(5%, #fff),
    color-stop(100%, #f1f1f1)
  );
  background: -webkit-linear-gradient(top, #fff 5%, #f1f1f1 100%);
  background: -o-linear-gradient(top, #fff 5%, #f1f1f1 100%);
  background: -ms-linear-gradient(top, #fff 5%, #f1f1f1 100%);
  background: linear-gradient(top, #fff 5%, #f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f1f1f1',GradientType=0 );

  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;

  -webkit-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);

  height: 50px;
  position: relative;
  width: 150px;
}

.cloud:after,
.cloud:before {
  background: #fff;
  content: "";
  position: absolute;
  z-index: -1;
}

.cloud:after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 70px;
  left: 60px;
  top: -50px;
  width: 70px;
}

.cloud:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  width: 80px;
  height: 80px;
  right: 50px;
  top: -30px;
}
</style>